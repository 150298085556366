/**
 * Format a number to a money string (format 9 999,00 €)
 * @param amount Amount to be formatted
 */
export function asMoney(amount: string) {
    return (parseFloat(amount) || 0)
        .toFixed(2)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        + ' €';
}
